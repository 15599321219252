/* You can add global styles to this file, and also import other style files */
@import "./static/bootstrap/bootstrap.scss";
@import "./static/bootstrap/bootstrap-grid.scss";
@import "./static/bootstrap/bootstrap-reboot.scss";
@import "./static/bootstrap/bootstrap-utilities.scss";
@import "./static/bootstrap/bootstrap-utilities.scss";

@import "./scss/variables";
@import "./scss/background";

@import "ngx-toastr/toastr-bs5-alert";
